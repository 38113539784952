.coop-section {
    margin-top: 60px;
    margin-bottom: 100px;
    .coop-title {
        text-align: center;
        margin-bottom: 50px;
    }
    .coop-img {
        width: 33.33333%;
        margin: 0 auto;
        .coop-images {
            width: 100%;
            height: 100px;
        }
    }
    .coop-name {
        margin-top: 20px;
        display: block;
        width: 100%;
        text-align: center;
    }
}