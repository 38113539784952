.site-admin {
    // margin-top: 80px;
    display: flex;
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
}

.site-main-admin{
    display: flex;
    flex-direction: column;
}

.main-admin-content {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin: 20px;
    height: 90vh;
    // overflow-y: hidden;
    overflow-y: scroll;
}

.header-admin {
    width: 100%;
}

.minimize {
    transform: translateX(-40px);
    .minimize-icon {
        svg {
            transform: rotate(-180deg);
        }
    }
}

.side_bar.minimize {
    padding: 0px;
    width: 0px;
    .minimize-icon {
        transform: translateX(50px);
    }
}

.side_bar_item--active {
    background-color: #DD483A !important;
}

.side_bar {
    width: 13%;
    padding: 20px;
    position: relative;
    z-index: 9999;
    background-color: var(--bg-nav-scroll);
    transition: .4s ease-out;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .minimize-icon {
        position: absolute;
        right: -10px;
        top: 40%;
        width: 20px;
        height: 100px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bg-nav-scroll);
        transition: .6s ease-in;
        cursor: pointer;
        svg {
            transition-delay: .2s;
            transition: .4s ease-out;
        }
    }
    &_inner {
        padding: 2.5%;
    }
    &_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    &_item {
        // width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 10px 15px;
        width: 100%;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        span {
            z-index: 99;
        }
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 0px;
            background-color: #DD483A;
            top: 0;
            left: 0;
            transition: .4s linear;
            z-index: 0;
        }
        &:hover {
            &::before {
                width: 100%;
            }
        }
    }
}
.sidebar_logo {
    display: flex;
    // justify-content: center;
    padding: 20px;
    margin-bottom: 10px;
    .header-sidebar-toggle {
        background: transparent;
        border: none;
    }
}

.site-main-admin {
    // position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    // transform: translateX(-10px);
}
.site-header-admin {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
    // position: fixed;
    height: 50px;
    background: linear-gradient(90deg, var(--bg-nav-scroll), #000);
    transition: .3s linear;
    .header-admin-user {
        display: flex;
        gap: 20px;
        .user-avatar {
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
        }
    }
    .admin-icon--logout, .admin-icons {
        cursor: pointer;
    }
}