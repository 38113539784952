.cta {
  .form-input {
    border-color: transparent;
    border-radius: 2px;
  }
}
input {
  background-color: #fff !important;
  margin-bottom: 10px !important;
  
}

.cta-inner {
  padding-left: 16px;
  padding-right: 16px;
  background-color: get-color(primary, 3);
  background-image: url(../../../images/cta-illustration.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
}

.group-input {
  display: flex !important;
  flex-direction: column;
  position: relative !important;
  .errorMessage {
    position: absolute;
    bottom: -15px;
    color: red;
  }
  textarea {
    padding: 11px 15px;
    display: block;
    width: 100% !important;
  }
}

@include media(">medium") {
  .cta {
    .form-input {
      width: 100% !important;
    }
  }

  .cta-inner {
    padding-left: 48px;
    padding-right: 48px;
  }
}
