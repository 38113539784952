@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    50% {
        opacity: 0.5;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(10px);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        display: block;
    }
    50% {
        opacity: 0.5;
        display: block;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes expand {
    0% {
        width: 0px;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}

@keyframes minimize {
    0% {
        width: 100%;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 0px;
    }
}

@keyframes animationMegaDropDownShow {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(-90deg);
    }
    50% {
        transform: rotate(-90deg) translateY(50%);
    }
    100% {
        transform: rotate(-90deg) translateY(75px);
    }
}

@keyframes animationMegaDropDownHide {
    0% {
        transform: rotate(-90deg) translateY(75px);
    }
    50% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(0);
    }
}

.hover-dropdown {
    position: relative;
    padding: 0 32px;
    cursor: pointer;
    .dropdown {
        position: absolute;
        width: 200px;
        background-color: #fff;
        border-radius: 4px;
        padding: 10px;
        right: -80px;
        top: 40px;
        box-shadow: 0px 0px 20px 0px #000;
        animation: fadeOut linear .3s;
        display: none;

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 20px;
            background-color: transparent;
            right: 0px;
            top: -20px;
        }

        &-item {
            position: relative;
            cursor: pointer;
            padding: 8px 0;
            &::after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                width: 0px;
                height: 2px;
                background-color: #000;
                border-radius: 8px;
                animation: minimize linear .4s;
            }
            &:hover {
                & {
                    padding-bottom: 12px;
                }
                &::after {
                    width: 100%;
                    animation: expand linear .4s;
                }
            }
        }
    }

    .dropdown-icon, .dropdown-icon-mega {
        transform: rotate(0);
        transition: all .3s;
    }

    &:hover {
        & .dropdown {
            display: block;
            transform: translateY(10px);
            animation: fadeIn linear .3s;
        }
        .dropdown-icon {
            transform: rotate(-180deg);
            transition: all .3s;
        }
    }

    .dropdown-item--mega {
        span {
            display: flex;
            gap: 10px;
            width: 100%;
        }
        .dropdown-icon-mega {
            animation: animationMegaDropDownHide ease-in-out .5s;
        }
    }

    .dropdown .dropdown-mega {
        display: none;
        top: 0;
        left: 108%;
    }

    .dropdown .dropdown-mega::before {
        position: absolute;
        content: "";
        width: 20px;
        height: 100%;
        background-color: transparent;
        left: -20px;
        top: 0px;
    }

    .dropdown-item--mega:hover {
        .dropdown-mega{
            display: block !important;
        }

        .dropdown-icon-mega {
            transform: rotate(-90deg) translateY(75px);
            animation: animationMegaDropDownShow ease-in-out .4s;
        }
    }

    span {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
}