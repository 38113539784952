.Header-ban-pages {
  border-bottom: 1px solid #252c2c;
  background-color: var(--bg-nav-scroll) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &.site-header {
    position: relative !important;
  }
}

.f_layout {
  display: flex;
  height: 600px;
  border-bottom: 1px solid #252c2c;
  @media screen and (max-width: 850px) {
    flex-direction: column;
    height: auto;
  }
  .f_layout-f {
    display: flex;
    flex-direction: column;
    width: 64%;
    @media screen and (max-width: 850px) {
      width: 100% !important;
      border-bottom: 1px solid #252c2c;
    }
    .f_layout-ff {
      border-right: 1px solid #252c2c;
      border-bottom: 1px solid #252c2c;
      height: 400px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        position: relative;
        letter-spacing: 3px;
        display: inline-block;
        span {
          padding: 5px 0;
          border-bottom: 1px solid #252c2c;
          background: -webkit-linear-gradient(left, #f36430, #faa22f);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          background-size: 200% auto;
          animation: linear linear 2s infinite;
          @keyframes linear {
            to {
              background-position: 200% center;
            }
          }
        }
      }
      p {
        position: absolute;
        top: -35px;
        right: 10%;
        font-size: 12px;
        color: #ccc;
        letter-spacing: 0;
      }
      @media screen and (max-width: 380px) {
        h1,
        span {
          font-size: 40px !important;
        }
        p {
          font-size: 10px;
        }
      }
    }
    .f_layout-fs {
      border-right: 1px solid #252c2c;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text_desc-ban {
        padding-right: 20px;
      }
      @media screen and (max-width: 530px) {
        flex-direction: column;
        .text_desc-ban {
          min-width: 80% !important;
          text-align: center;
          padding-right: 0;
        }
      }
    }
  }
  .Ban-slides {
    width: 36%;
    flex: 1;
    height: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    @media screen and (max-width: 850px) {
      width: 100% !important;
      .Ban-slides-container {
        margin: 40px 0;
      }
    }
  }
}

.italic {
  font-style: italic;
}
.flex-Col {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    & > div {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.icon {
  display: inline-flex;
  border-radius: 50%;
  background-color: #6063fe;
}
.mission-item,
.advantage-item {
  flex: 1 0;
  margin: 15px !important;
}
.advantage-item {
  max-width: 300px;
  min-width: 300px;
  background-color: rgb(30, 41, 59);
  padding: 30px 15px;
  border-radius: 10px;
  color: #fff;
  opacity: 0.7;
}
.Ban-mission,
.Ban-advantages {
  text-align: center;
  padding: 50px 8%;
  position: relative;
  border-bottom: 1px solid #252c2c;
  h1 {
    padding-bottom: 40px;
  }
  p {
    padding-top: 20px;
  }
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.text_desc-ban {
  width: 50%;
  padding-bottom: 10px;
}

.btn_desc-ban {
  padding: 12px 20px;
  background-color: #6063fe;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -10px;
    border-top: 23px solid #6063fe;
    border-bottom: 24px solid transparent;
    border-left: 5px solid #6063fe;
    border-right: 5px solid transparent;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -10px;
    border-top: 24px solid transparent;
    border-bottom: 24px solid #6063fe;
    border-left: 5px solid transparent;
    border-right: 5px solid #6063fe;
  }
}

.Ban-register-btn {
  position: relative;
  overflow: hidden;
  width: 180px;
  display: flex;
  justify-content: center;
  .shine {
    position: absolute;
    width: 200px;
    height: 25px;
    transform: rotate(-130deg);
    background-color: #fff;
    left: -115px;
    top: 42px;
  }
  &:hover {
    .shine {
      left: 100px;
      top: -22px;
      transition: 0.3s linear;
    }
  }
}

.Ban-slides {
  display: flex;
  align-items: center;
  justify-content: center;
  &-container {
    width: 90%;
    height: 61%;
  }
  &-controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    .Ban-slides-next {
      cursor: pointer;
      margin-left: 25px;
    }
    .Ban-slides-previous {
      transform: rotate(180deg);
      cursor: pointer;
      margin-right: 25px;
    }
  }
}

.Slogan_page {
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #252c2c;
  padding-bottom: 16px;
  margin: 0 5px;
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 40px !important;
    }
  }
  @media screen and (max-width: 520px) {
    h1 {
      font-size: 30px !important;
    }
  }
}

.h1-header-banpages {
  font-family: "Sriracha", cursive;
  font-size: 54px;
  font-style: italic;
}

.texture {
  position: absolute;
  z-index: -1;
  opacity: 0.8;
}
.rushRight {
  animation: rushRight 40s linear infinite;
}
.rushLeft {
  animation: rushLeft 30s linear infinite;
}
.rushBottom {
  animation: rushBottom 50s linear infinite;
}
@keyframes rushBottom {
  from {
    opacity: 0.3;
    transform: translate(0);
  }
  65% {
    opacity: 0.6;
    transform: translate(1000%, 1000%) scale(0.9) rotate(-65deg);
  }
  100% {
    opacity: 0.3;
    transform: translate(0) scale(1) rotate(0);
  }
}
@keyframes rushLeft {
  from {
    opacity: 0.3;
    transform: translate(0);
  }
  30% {
    opacity: 0.6;
    transform: translate(-200%, -20%) rotate(65deg);
  }
  65% {
    opacity: 0.1;
    transform: translate(-20%, -200%) scale(0.9);
  }

  100% {
    opacity: 0.3;
    transform: translate(0) scale(1) rotate(0);
  }
}
@keyframes rushRight {
  from {
    opacity: 0.3;
    transform: translate(0);
  }
  10% {
    opacity: 0.6;
    transform: translate(20%, 20%);
  }
  20% {
    opacity: 0.1;
    transform: translate(50%, 30%) scale(0.9) rotate(65deg);
  }
  50% {
    opacity: 0.6;
    transform: translate(150%, 20%) scale(0.8);
  }
  90% {
    opacity: 0.4;
    transform: translate(20%, 0) scale(0.9) rotate(0);
  }
  100% {
    opacity: 0.3;
    transform: translate(0) scale(1);
  }
}

// @import "../../../assets/scss/core/elements/containers";
