// .errorMessage {
//     height: 20px;
// }
.login-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #27294E;
    background-image: url('../../../images/illustration-section-01.svg');
    background-repeat: no-repeat;
    &-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 350px;
        min-height: 450px;
        background-color: #fff;
        padding: 20px 30px;
        border-radius: 8px;
        position: relative;
        .login-header {
            display: flex;
            gap: 30px;
            h1 {
                font-size: 24px;
                text-align: center;
                margin: 0;
                background: -webkit-linear-gradient(left, #f36430, #faa22f);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-size: 200% auto;
                animation: linear linear 2s infinite;
                @keyframes linear {
                    to {
                        background-position: 200% center;
                    }
                }
            }
        }
        .form-login {
            display: flex;
            flex-direction: column;
            gap: 30px;
            input {
                padding: 10px 15px;
                font-size: 14px !important;
                font-weight: 600;
            }
            .login-btn {
                font-size: 14px;
                padding: 14px 20px;
                margin-top: 20px;
                border: none;
                border-radius: 4px;
                width: 100%;
                background-color: #6063fe;
                color: #fff;
                font-weight: 600;
                cursor: pointer;
            }
            .form-login-input {
                position: relative;
                margin-top: 20px;
                .title {
                    position: absolute;
                    left: 10px;
                    top: 5px;
                    transition: .2s ease-out;
                    cursor: text;
                }
                input {
                    border: 1px solid #f36430;
                }
                .title {
                    top: -8px;
                    display: inline-block;
                    color: #000;
                    background-color: #fff;
                    line-height: 1 !important;
                    cursor: default;
                    z-index: 10;
                }
            }
            .remember-checkbox {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                input {
                    width: fit-content;
                }
                .remember-label {
                    font-size: 14px;
                }
            }
        }
    }
}