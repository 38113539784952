.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // height:100vh;
}
.article {
  white-space: pre-wrap;
  margin-bottom: 100px;
}
.article-left {
  grid-column: 1/ 3;
  padding-right: 20px;
  img {
    width: 100%;
    margin-bottom: 32px;
  }
}
.article-img {
  width: 100%;
  margin-bottom: 32px;
  // height: 400px;
}
.article-tags {
  background-color: #343231;
  padding: 4px 12px;
  display: flex;
  flex-wrap: wrap;
}
.article-item {
  color: #fff;
  font-size: 12px;
  background-color: #555555;
  padding: 0 12px;
  margin: 4px 0;
  margin-right: 10px;
  &.primary {
    background-color: #6163ff !important;
  }
}
.article-right {
  padding-left: 20px;
  .article-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .article-item {
    padding: 15px;
  }
}

.text-article {
  span{
    color: #ccc !important;
  }
  a{
    color: #6163ff !important;
  }
}

@media screen and (max-width: 800px) {
  .grid-3 {
    display: flex;
    flex-direction: column;
  }
  .article-right,
  .article-left {
    padding: 0;
  }
  .article-right {
    padding-top: 20px;
  }
}

.Header-article-pages {
  background-color: var(--bg-nav-scroll) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed !important;

  // &.site-header {
  //   // position: relative !important;
  // }
}
.text-center {
  padding-top: 60px;
  text-align: center;
}
.slider-controls {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 100px;
  .slides-previous,
  .slides-next {
    padding: 0 40px;
  }
  .slides-previous {
    transform: rotate(180deg);
  }
}
.article-slider {
  background-color: #343231 !important;
  display: flex;
  
  img {
    width: 100%;
    max-height: 550px;
    object-position: 50% 60%;
    object-fit: cover;
    margin: 0 auto;
  }
  .slider-content {
    padding: 20px;
    padding-top: 0;
    h2{
      margin-top:40px;
      margin-bottom:0
    }
    p {
      padding: 10px 0;
    }
  }
}
.article-main-content {
  .search-form {
    height: 80px;
    border-radius: 50px;
    overflow: hidden;
    form {
      padding: 0 20px;
      width: 100%;
      height: 100%;
      background-color: #25222d !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      .input-search {
        padding: 0 10px;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 24px;
        border: 1px solid transparent;
        background-color: #25222d !important;
        margin-bottom: 0 !important;
        border-radius: 0;
      }
    }
  }
  .article-lists {
    margin-top: 96px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 50px;
  }
  .article-item {
    grid-column: span 4;
    background-color: #25222d !important;
    border-radius: 10px;
    margin: 15px;

    .container {
      width: 100%;
      height: 100%;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      .article-main-img {
        img {
          width: 100%;
          height: 230px;
          border-radius: 10px;
        }
      }
      .article-main-content {
        padding-right: 10px;
        flex: 1;
        .article-title {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 0;
        }

        .article-time {
          display: inline-flex;
          svg {
            fill: #9ca9b3;
          }

          p {
            color: #fff;
            font-size: 12px;
            margin-top: 8px;
            margin-bottom: 0;
            margin-left: 6px;
          }
        }

        .article-overview {
          font-size: 14px;
          font-weight: 700;
          color: #ccc;
          line-height: 24px;
          margin-bottom: 30px;
          height: 65px;
        }
      }

      .see-detail {
        width: 100%;
        background-color: #6163ff;
        border-radius: 5px;

        .see-detail-btn {
          color: #fff;
          font-size: 16px;
          padding: 10px 0;
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .load-more-btn {
    width: 50%;
    height: 50px;
    margin: 0 auto;
    background-color: #6163ff;
    border-radius: 5px;
    button {
      background-color: #6163ff;
      border-radius: 5px;
      color: #fff;
      font-size: 16px;
      padding: 10px 0;
      width: 100%;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      outline: none;
    }
  }
  @media screen and (max-width: 900px) {
    .article-item {
      grid-column: span 6;
    }
  }
  @media screen and (max-width: 600px) {
    .article-item {
      grid-column: span 12;
    }
  }
}
.form-comment {
  background-color: rgb(255, 255, 255) !important;
  margin-top: 10px;
}
::placeholder {
  color: #ccc !important;
  font-size: 24px !important;
}
