.Ban-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-bottom: 1px solid #ccc;
  &-slider {
    width: 100%;
  }
}

@keyframes showUp {
  from {
    opacity: 0;
    // transform: scale(0);
  }
  to {
    opacity: 0.5;
    // transform: scale(1);
  }
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 80%;
  }
}

.slide-item {
  height: 200px;
  position: relative;
  cursor: pointer;
  margin: 10px;
  overflow: hidden;
  &:hover {
    .overlay {
      animation: showUp 0.4s linear;
      transform: scale(1);
    }
    .slide-title {
      animation: showUp 0.4s linear;
      transform: scale(1);
      animation-delay: 0.1s;
    }
  }
}

.slide-title {
  position: absolute;
  bottom: 45%;
  display: inline-block;
  width: 100%;
  text-align: center;
  z-index: 999;
  font-weight: 600;
  color: #fff;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  transform: translateY(200px);
  padding: 10px 0px;
  background-color: #000;

  &::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 1px;
    bottom: 0;
    left: 30px;
    background-color: #fff;
    transition: 0.3s ease-out;
  }
  &:hover {
    letter-spacing: 2px;
    word-spacing: 2px;
    &::after {
      animation: expand 0.3s ease-in;
      transform: scale(1);

      width: 80%;
    }
  }
}
@media screen and (max-width: 800px) {
  .slide-title {
    word-spacing: 2px;
    letter-spacing: 2px;
    // background-color: #000;
    &::after {
      transform: scale(1);
      display: none !important;
      animation: none !important;
    }
  }
  .slide-item {
    .overlay {
      animation: none !important;
      transform: scale(1);
    }
    .slide-title {
      animation: none !important;
      transform: scale(1);
    }
  }
}
.slick-arrow {
  display: none !important;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: #000;
  opacity: 0.5;
  //   transform: scale(1);
  transition: 0.4s linear;
}
